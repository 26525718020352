
.settingsMenu {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;  
    flex-direction: row;
    width: 35vmin;
    height: 12vmin;      
  }
  
  .settingsButton {
    border-radius: 2vmin;
    border: none ; 
    background: rgba(255, 255, 255,1);
    margin-left: 1vmin;
    width: 9vmin;
    height: 9vmin;  
    padding: 1vmin;
  }
  
  .mainmenuButton {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    border-radius: 2vmin;
    margin-left: 1vmin;
    border: none; /*0.5vmin solid black; */
    background: rgba(255, 255, 255,1);
    width: 11vmin;
    height: 11vmin;  
    padding: 1vmin;
  }
  
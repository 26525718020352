

.mainMenuHoleScreen {

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: auto;
    width: 100vw;
}

.mainMenuBorder {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    background-color: transparent; /* white; */
    height: auto;
    width: 90vmin;
    overflow:auto;
 /*   border: 0.5vmin solid black; 
    border-radius: 7vmin;
    background-color: rgb(227, 227, 227) ;  */
}

.mainMenuContainer {

    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    background-color: white;
    height: auto;
    margin: 2vmin;
    border-radius: 7vmin;
    border: 0.5vmin solid black;

}



@media screen and (orientation:portrait) {

}

.mainMenuHeaderConnainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    padding-top: 6vmin;
    padding-bottom: 6vmin;
 /*   border: 0.5vmin solid black;
    border-top-right-radius: 7vmin;
    border-top-left-radius: 7vmin;  */
    
}

.mainMenuHeader {
 /*   font-size: 6vmin;
    color:rgb(236, 102, 7) ;
    font-weight: bold; */
    width: 65%;
    height: auto;
}

.mainMenuSubHeader {
    padding-top: 1vmin;
    font-size: 4.5vmin;
}

.mainMenuEpName {
    padding: 2.5vmin;
    font-size: 4vmin;
    font-weight: bold;
    border-top-left-radius: 4vmin;
    border-top-right-radius: 4vmin;
  /*  background-color: rgb(227, 227, 227);  */
    border: 0.5vmin solid black;
    border-bottom: none;  
}


.episodeContainer, .lastEpisodeContainer  {
    padding-top: 4vmin;
    padding-bottom: 4vmin;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;

    border-top: 0.5vmin solid black;  
    background-color: rgb(65, 65, 85);

 /*   width: 70vmin;
    height: auto;
    background-color: rgb(240, 240, 240);
    border-radius: 5%; 
    margin-bottom: 2vh; */
}

.episodeContainer {
    border-bottom: 0.5vmin solid black; 
    margin-bottom: 4vmin;
}

.lastEpisodeContainer {
    border-bottom-right-radius: 6vmin;
    border-bottom-left-radius: 6vmin;
}  

.mainMenuEpDesc {
    margin-top: 2vmin;
    width: 75%;
    padding: 2vmin;
    text-align:  left;
    font-size: 3.4vmin;
    color: white;
 /*   border-left: 2vmin solid green; 
    background-color: rgb(232, 232, 232);
    border-top-right-radius: 4vmin;
    border-bottom-right-radius: 4vmin; */
}

.episodeButtonContainer {
    margin-top: 2vmin;
    display: flex;
    align-items: center;
    justify-content: space-evenly ;
    flex-direction: row;
    width: auto;
    
  /*  border: 1px solid yellow; */

}

.mainMenuButton1, .mainMenuButton2 {
    border-radius: 6vmin;
    border: 0.5vmin solid black;
    background: rgb(200, 214, 77);
    padding: 3vmin;
    font-size:4.2vmin; 
    margin-left: 1vmin;
    margin-right: 1vmin;
}

.mainMenuButton2 {
      background-color: rgb(239, 133, 181);
}

.episodeIcon, .episodeIconLast {
    width: 60%;
    height: auto;
    border-radius: 50%;
}

.episodeIcon {
    border:  0.5vmin solid black;
}

#iconComingSoon {
    animation: rotation 15s infinite linear;
}

.ModalBox {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
}

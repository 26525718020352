.App {
  text-align: center;
  background-color: transparent;
  overflow: hidden; 
}

.InitialLoaging {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.loagingImg {
  width: 40vmin;
  height: 40vmin;
}




@font-face {
    font-family: "major font";
    src: url("SourceSans3-Regular.ttf.woff") ;
    font-weight: normal;
} 

@font-face {
    font-family: "major font";
    src: url("SourceSans3-Bold.ttf.woff") ;
    font-weight: bold;
} 

body {
    margin: 0;
 /*  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;   
 */
    font-family: "major font"; 
    line-height: 1.35;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;  

    background-color: rgb(255, 255, 255);
 /*   overflow: hidden; */
    background-image: url('backgr.png');
    background-repeat: repeat;
    background-size: 100vmin auto;

}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
} 

* {
    box-sizing: border-box;
}

.slide {

}


.hidden {
  opacity: 0;
}

.appears {
    animation: slideEnter 500ms;
}

.disappears { 
  animation: slideExit 500ms;
}

@keyframes slideEnter {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes slideExit {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

#loading {
    animation: rotation 2s infinite linear;
}


@keyframes rotation {
    from {
            transform: rotate(0deg);
    }
    to {
            transform: rotate(359deg);
    }
}


.optionButton, .adSlideButton {
    border-radius: 8vmin;
    border: 0.5vmin solid black;
    background: white;
    padding: 4vmin;
    font-size: 4vmin;
}


.adSlideButton {
    position: relative;
    background: white; /* rgb(200, 214, 77); */
}

.adButtonImage {
    margin-left: 3vmin;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    height: 10vmin ;
}

.adButtonText {
    margin-left: 11vmin;
}

.nextSlideButton {
    border-radius: 8vmin;
    border: 0.5vmin solid black;
    background: white;
    padding: 5vmin;
    font-size:6vmin;
}


.emptyTopBar {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc((100vmax - 100vmin)/5);  
    width: 100vmin;
  /*  border: 0.5vmin solid red; */
    flex: 0 2 auto;
    z-index: 2;

}

.emptyCenterColumn {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vmin;
    width: 100vmin;
    z-index: 2;
  /*  border: 0.5vmin solid green; */
 
}

.contentContainer, .centerColumn {

    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vmin;
    width: 100vmin;
    font-size: 5vmin;
    z-index: 2;

}

.centerColumn {

    border: 0.5vmin solid black; 
    z-index: 1;
}



@media screen and (orientation:landscape) {

    .row {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 100vmin;
        width: 100vmax; 
        border: none;
    }
    .leftColumn, .rightColumn {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        width: 40vmin; /* calc((100vmax - 100vmin)/2); */

        height: 100vmin;
        /*padding-top: 10vmin;
        padding-bottom: 10vmin; */
        flex: 0 10 auto;
        z-index: 2;
   /*     border: 0.5vmin solid red; */

    }
    .leftColumn {
        align-items: flex-end;
        margin-right: 3vmin;
    }
    .rightColumn {
        align-items: flex-start;
        margin-left: 3vmin;
    }
    .centerColumn {
        position: absolute;
        left: calc(50vmax - 50vmin); 
        top: 0;
    }
}



@media screen and (orientation:portrait) {
    .row {
        display: flex;
        flex-direction: column;
        justify-content: top;
        align-items: center;
        width: 100vmin;
        height: 100vmax;
        border: none;
    }

    .buttonContainer {
        display: flex;
        flex-direction: row-reverse;
        flex-wrap: wrap;
        width: 100vmin;
        justify-content: space-evenly;
        height: 100vmin;
        align-items: flex-start;
        align-content: flex-start;

  /*      border: 0.5vmin solid red; */
        z-index: 2;
        flex: 0 1000 auto;
    }
    
    .optionButton, .nextSlideButton, .adSlideButton {
        max-width: 45vmin;
        width: auto;
        min-height: 10vmin;
       margin-top: 2vmin;
    }

    .centerColumn {
        position: absolute;
        top: calc((100vmax - 100vmin)/5); 
    }
  }




.SpeechContainer0, .SpeechContainer1, .SpeechContainer2, .SpeechContainer3 {
    z-index: 3;
    display: flex;
    flex-direction: column;
    width: 100vmin;
    height: 100vmin;
    align-items: center;
    justify-content: flex-end;
}

.SpeechContainer1, .SpeechContainer2, .SpeechContainer3 {
    justify-content: center;
}

.Speech0 , .Speech1, .Speech2, .Speech3 {
    z-index: 4;
    max-width: 80vmin;
    padding-left: 5vmin;
    padding-right: 5vmin;
    padding-bottom: 0vmin;
    padding-top: 0vmin;
    font-size: 5vmin;
    border: 0.5vmin solid black;
    background-color: rgba(255, 255, 255, 1);
    animation: slideEnter 500ms;

 }

.Speech0 {
    border-top-left-radius: 6vmin;
    border-top-right-radius: 6vmin;
}

/* comments, thoughts */
.Speech1 {
    border-style: dashed;
    border-radius: 6vmin;
}

/* text on surface */
.Speech2 {
    padding-left: 2vmin;
    padding-right: 1vmin;
    text-align: left;
    font-size: 5.5vmin;
    border: none;
    background-color: transparent;
}

/* header */
.Speech3 {
    font-size: 8vmin;
    padding-left: 10vmin;
    padding-right: 10vmin;
    border-style: dashed;
    border-radius: 6vmin;
 
}

.centerImage {
    display: block;
    position: absolute;
    width: 100vmin;
    height: 100vmin;
   
    border: 0.5vmin solid black; 
    background-color: black;
}
